import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import './assets/icon/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
ElementUI.Select.computed.readonly = function () {
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
  return !(this.filterable || this.multiple || !isIE) && !this.visible;
};
Vue.use(ElementUI, {size: 'small'});
import './assets/tw.css'
import Vant from 'vant';
import 'vant/lib/index.less';

Vue.use(Vant);

import { register } from 'swiper/element/bundle';
register();

import request from '@/utils/request'
import './registerServiceWorker'
Vue.prototype.axios = request

Vue.prototype.host = request.defaults.baseURL.slice(0, -4)

Vue.prototype.go = (path) => {
  router.push(path)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <this-nav />
    <div class="px-2 text-center">
      <h3 class="text-2xl mt-4">激活码</h3>
      <input
        class="mt-8 w-full h-9 px-2 border border-gray-800 placeholder:text-sm placeholder:text-gray-600"
        type="text"
        v-model="code"
        placeholder="请输入激活码"
      />
      <div class="mt-10">
        <van-button
          type="primary"
          :disabled="loading"
          class="w-[287px]"
          @click="onSubmit"
          >验证激活码</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import thisNav from "@/components/navbar.vue";

export default {
  components: { thisNav },
  data() {
    return {
      code: "",
      loading: false,
    };
  },
  created() {},
  methods: {
    onSubmit() {
      if (this.code.trim() === "") {
        return this.$toast("激活码必须填写");
      }
      this.$toast.loading({
        message: "请稍等...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.loading = true;
      // this.axios.post('')
      this.axios
        .get("front/index/valid/" + this.code)
        .then((res) => {
          this.$router.push("/forms/" + this.code);
        })
        .catch(()=>{
            this.$toast("激活码失效");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
